import React from 'react';
import Link from 'gatsby-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';
import { COLOR } from '../styles/variables';

type Props = {
  pageContext: {
    currentPage: number;
    isFirst: boolean;
    isLast: boolean;
    catname: string;
    catSlug: string;
  };
  type: 'category' | 'all';
};

const Pagenation = (props: Props): JSX.Element => {
  const { type, pageContext } = props;

  let prevTo;
  let nextTo;

  if (type === 'category') {
    // カテゴリー絞りの場合
    if (pageContext.currentPage === 2) {
      prevTo = `/category/${pageContext.catSlug}/`;
    } else {
      prevTo = `/category/${pageContext.catSlug}/${
        pageContext.currentPage - 1
      }/`;
    }
    nextTo = `/category/${pageContext.catSlug}/${pageContext.currentPage + 1}/`;
  } else {
    // すべての記事を出す場合
    if (pageContext.currentPage === 2) {
      prevTo = `/article/`;
    } else {
      prevTo = `/article/${pageContext.currentPage - 1}/`;
    }
    nextTo = `/article/${pageContext.currentPage + 1}/`;
  }

  return (
    <UL_Wrap>
      {!pageContext.isFirst && (
        <LI_Prev>
          <Link to={prevTo} rel="prev">
            <FontAwesomeIcon icon={faChevronLeft} />
            <SPAN_Text>PREVIOUS</SPAN_Text>
          </Link>
        </LI_Prev>
      )}
      {!pageContext.isLast && (
        <LI_Next>
          <Link to={nextTo} rel="next">
            <SPAN_Text>NEXT</SPAN_Text>
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </LI_Next>
      )}
    </UL_Wrap>
  );
};

const UL_Wrap = styled.ul`
  margin-top: 1.8em;
  list-style: none;
  display: flex;

  a {
    padding: 0.5em 1em;
    border-radius: 1em;
    background-color: ${COLOR.pagenation.wrapBg};
    color: ${COLOR.pagenation.wrapText};
    font-size: 1.4rem;
    display: flex;
    align-items: center;
  }
`;

const SPAN_Text = styled.span`
  font-size: 1.2rem;
`;

const LI_Prev = styled.li`
  ${SPAN_Text} {
    margin-left: 10px;
  }
`;

const LI_Next = styled.li`
  margin-left: auto;

  a {
    justify-content: flex-end;
  }

  ${SPAN_Text} {
    margin-right: 10px;
  }
`;

export { Pagenation };

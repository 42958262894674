import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { ArticleList } from '../components/ArticleList';
import { Pagenation } from '../components/Pagenation';
import { Seo } from '../components/Seo';
import { CommonBlock } from '../components/CommonBlock';
import { CommonContent } from '../components/CommonContent';
import { MODULE_TITLE, META_INFO } from '../utils/word';

export default ({ data, location, pageContext }): JSX.Element => {
  const { catname, catSlug } = pageContext;
  let pageDesc;

  // カテゴリごとにDescription文言を最適化
  switch (catSlug) {
    case 'improvement':
      pageDesc = META_INFO.CATEGORY.DESCRIPTION_IMPROVEMENT;
      break;
    case 'gatsbyjs':
      pageDesc = META_INFO.CATEGORY.DESCRIPTION_GATSBYJS;
      break;
    case 'javascript':
      pageDesc = META_INFO.CATEGORY.DESCRIPTION_JAVASCRIPT;
      break;
    case 'css':
      pageDesc = META_INFO.CATEGORY.DESCRIPTION_CSS;
      break;
    case 'html':
      pageDesc = META_INFO.CATEGORY.DESCRIPTION_HTML;
      break;
    case 'siteproduction':
      pageDesc = META_INFO.CATEGORY.DESCRIPTION_SITEPRODUCTION;
      break;
    case 'ux':
      pageDesc = META_INFO.CATEGORY.DESCRIPTION_UX;
      break;
    case 'pr':
      pageDesc = META_INFO.CATEGORY.DESCRIPTION_PR;
      break;
    default:
      pageDesc = `${catname}${META_INFO.CATEGORY.DESCRIPTION_DEFAULT}`;
  }

  return (
    <Layout>
      <Seo
        pagetitle={`${catname}${META_INFO.CATEGORY.TITLE}`}
        pagedesc={pageDesc}
        pagepath={location.pathname}
      />
      <CommonContent>
        <CommonBlock title={`${MODULE_TITLE.CATEGORY_ARTILCE} ${catname}`}>
          <ArticleList data={data} />
          <Pagenation pageContext={pageContext} type="category" />
        </CommonBlock>
      </CommonContent>
    </Layout>
  );
};

export const query = graphql`
  query($catid: String!, $skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { order: DESC, fields: publishDate }
      skip: $skip
      limit: $limit
      filter: { category: { elemMatch: { id: { eq: $catid } } } }
    ) {
      edges {
        node {
          title
          id
          slug
          eyecatch {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
            description
          }
        }
      }
    }
  }
`;
